import {Heading, HStack, Text, ThemeIconKey, VStack} from 'platform/foundation';

import {suffixTestId, TestIdProps} from 'shared';

import {AvatarIcon} from '../../../components/AvatarIcon/AvatarIcon';

interface SpecificationItemProps extends TestIdProps {
  value: string | number;
  category: string;
  icon: ThemeIconKey;
}

export function SpecificationItem(props: SpecificationItemProps) {
  const valueIsNotEmptyString = props.value !== '';
  return (
    <HStack spacing={4} align="center">
      <AvatarIcon icon={props.icon} />
      <VStack spacing={props.category && valueIsNotEmptyString ? 1 : 0}>
        <Text data-testid={suffixTestId('category', props)} size="xSmall" color="secondary">
          {props.category}
        </Text>
        <Heading data-testid={suffixTestId('value', props)} size={4}>
          {String(props.value)}
        </Heading>
      </VStack>
    </HStack>
  );
}
