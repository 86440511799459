import {Spinner, ThemeIconKey, ThemeColorPath, Box, Icon} from 'platform/foundation';
import styled, {css} from 'styled-components';

import {HTMLAttributeAnchorTarget, MouseEventHandler} from 'react';
import {Link, To} from 'react-router-dom';

import {suffixTestId, TestIdProps} from 'shared';

export type ButtonVariant =
  | 'primary'
  | 'secondary'
  | 'danger'
  | 'success'
  | 'outlined'
  | 'ghost'
  | 'ghostLink'
  | 'errorLink'
  | 'link'
  | 'dangerOutlined'
  | 'dangerGhost'
  | 'dangerLink';

export type ButtonSize = 'small' | 'default';

export interface ButtonProps extends TestIdProps {
  title?: string;
  size?: ButtonSize;
  variant?: ButtonVariant;
  isLoading?: boolean;
  isFullWidth?: boolean;
  isDisabled?: boolean;
  leftIcon?: ThemeIconKey;
  rightIcon?: ThemeIconKey;
  iconColor?: ThemeColorPath;
  type?: 'submit' | 'reset' | 'button';
  onClick?: MouseEventHandler<HTMLButtonElement>;
  /**
   * Button element HTML5 attribute used in FormButton
   */
  form?: string;
  to?: To;
  target?: HTMLAttributeAnchorTarget;
  replace?: boolean;
}

export function Button(props: ButtonProps) {
  const content = (
    <>
      {props.isLoading && (
        <Spinner variant="overlay" size="small" data-testid={suffixTestId('button', props)} />
      )}

      {props.leftIcon && (
        <Box paddingRight={1}>
          <Icon size={4} value={props.leftIcon} color={props.iconColor} />
        </Box>
      )}

      {props.title}

      {props.rightIcon && (
        <Box paddingLeft={1}>
          <Icon size={4} value={props.rightIcon} color={props.iconColor} />
        </Box>
      )}
    </>
  );

  if (props.to) {
    return (
      <StyledLink
        to={props.to}
        target={props.target}
        replace={props.replace}
        $isLoading={props.isLoading}
        $variant={props.variant}
        $size={props.size}
        $isFullWidth={props.isFullWidth}
        data-testid={suffixTestId('button', props)}
      >
        {content}
      </StyledLink>
    );
  }

  return (
    <StyledButton
      role="button"
      type={props.type ?? 'button'}
      disabled={props.isDisabled}
      $isLoading={props.isLoading}
      $variant={props.variant}
      $size={props.size}
      form={props.form}
      onClick={props.onClick}
      $isFullWidth={props.isFullWidth}
      data-testid={suffixTestId('button', props)}
    >
      {content}
    </StyledButton>
  );
}

// eslint-disable-next-line eag/no-css-property
const baseStyles = css`
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: ${({theme}) => theme.fontSizes.text.small};
  font-weight: ${({theme}) => theme.fontWeights.regular};
  line-height: ${({theme}) => theme.lineHeights.text.small};
  letter-spacing: 0;
  white-space: nowrap;
  transition: all 250ms;

  &:focus-visible {
    outline: none;
  }

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }
`;

const getVariantStyles = (variant?: ButtonVariant) => {
  switch (variant) {
    case 'primary':
      // eslint-disable-next-line eag/no-css-property
      return css`
        background: ${({theme}) => theme.colors.general.accent};
        color: ${({theme}) => theme.colors.general.white};
        border-radius: ${({theme}) => theme.getSize(1)};

        &:hover {
          background: ${({theme}) => theme.colors.palettes.blue[70][100]};
        }

        &:active {
          background: ${({theme}) => theme.colors.palettes.blue[80][100]};
        }

        &:focus-visible {
          background: ${({theme}) => theme.colors.general.accent};
          box-shadow: 0 0 0 4px ${({theme}) => theme.colors.palettes.blue[40][100]};
        }

        &:disabled {
          background: ${({theme}) => theme.colors.general.accent};
        }
      `;
    case 'secondary':
      // eslint-disable-next-line eag/no-css-property
      return css`
        background: ${({theme}) => theme.colors.palettes.neutral[30][100]};
        color: ${({theme}) => theme.colors.text.primary};
        border-radius: ${({theme}) => theme.getSize(1)};

        &:hover {
          background: ${({theme}) => theme.colors.palettes.neutral[40][100]};
        }

        &:active {
          background: ${({theme}) => theme.colors.palettes.neutral[50][100]};
        }

        &:focus-visible {
          background: ${({theme}) => theme.colors.palettes.neutral[30][100]};
          box-shadow: 0 0 0 4px ${({theme}) => theme.colors.palettes.blue[40][100]};
        }

        &:disabled {
          background: ${({theme}) => theme.colors.palettes.neutral[30][100]};
        }
      `;
    case 'outlined':
      return css`
        background: ${({theme}) => theme.colors.general.white};
        color: ${({theme}) => theme.colors.text.primary};
        border: 1px solid;
        border-color: ${({theme}) => theme.colors.palettes.neutral[70][100]};
        border-radius: ${({theme}) => theme.getSize(1)};

        &:hover {
          background: ${({theme}) => theme.colors.general.white};
          border: 1px solid;
          border-color: ${({theme}) => theme.colors.palettes.neutral[200][100]};
        }

        &:active {
          background: ${({theme}) => theme.colors.general.white};
          border: 1px solid;
          border-color: ${({theme}) => theme.colors.palettes.neutral[900][100]};
        }

        &:focus-visible {
          background: ${({theme}) => theme.colors.general.white};
          box-shadow: 0 0 0 1px ${({theme}) => theme.colors.palettes.neutral[70][100]};
        }

        &:disabled {
          background: ${({theme}) => theme.colors.general.white};
          border: 1px solid;
          border-color: ${({theme}) => theme.colors.palettes.neutral[70][100]};
        }
      `;
    case 'link':
      // eslint-disable-next-line eag/no-css-property
      return css`
        background: ${({theme}) => theme.colors.general.transparent};
        color: ${({theme}) => theme.components.Button.variants.link.color};
        border-radius: 0;
        padding-inline: 0;

        &:hover {
          background: ${({theme}) => theme.colors.general.transparent};
          text-decoration: underline;
        }

        &:active {
          background: ${({theme}) => theme.colors.general.transparent};
        }

        &:focus-visible {
          background: ${({theme}) => theme.colors.general.transparent};
          box-shadow: 0 0 0 4px ${({theme}) => theme.colors.palettes.blue[40][100]};
        }

        &:disabled {
          background: ${({theme}) => theme.colors.general.transparent};
        }
      `;
    case 'ghost':
      // eslint-disable-next-line eag/no-css-property
      return css`
        background: ${({theme}) => theme.colors.general.transparent};
        border-radius: ${({theme}) => theme.getSize(1)};

        &:hover {
          background: ${({theme}) => theme.components.Button.variants.ghost.hover.backgroundColor};
        }

        &:active {
          background: ${({theme}) => theme.components.Button.variants.ghost.active.backgroundColor};
        }

        &:focus-visible {
          background: ${({theme}) => theme.colors.general.transparent};
          box-shadow: 0 0 0 4px ${({theme}) => theme.colors.palettes.blue[40][100]};
        }

        &:disabled {
          background: ${({theme}) => theme.colors.general.transparent};
        }
      `;
    case 'ghostLink':
      // eslint-disable-next-line eag/no-css-property
      return css`
        background: ${({theme}) => theme.colors.general.transparent};
        color: ${({theme}) => theme.colors.text.link};
        border-radius: ${({theme}) => theme.getSize(1)};

        &:hover {
          background: ${({theme}) => theme.colors.palettes.neutral[30][100]};
        }

        &:active {
          background: ${({theme}) => theme.colors.palettes.neutral[40][100]};
        }

        &:focus-visible {
          background: ${({theme}) => theme.colors.general.transparent};
          box-shadow: 0 0 0 4px ${({theme}) => theme.colors.palettes.blue[40][100]};
        }

        &:disabled {
          background: ${({theme}) => theme.colors.general.transparent};
        }
      `;
    case 'errorLink':
      // eslint-disable-next-line eag/no-css-property
      return css`
        background: ${({theme}) => theme.colors.general.transparent};
        color: ${({theme}) => theme.colors.text.danger};
        border-radius: 0;

        &:hover {
          background: ${({theme}) => theme.colors.general.transparent};
          text-decoration: underline;
        }

        &:active {
          background: ${({theme}) => theme.colors.general.transparent};
        }

        &:focus-visible {
          background: ${({theme}) => theme.colors.general.transparent};
          box-shadow: 0 0 0 4px ${({theme}) => theme.colors.palettes.blue[40][100]};
        }

        &:disabled {
          background: ${({theme}) => theme.colors.general.transparent};
        }
      `;
    case 'danger':
      // eslint-disable-next-line eag/no-css-property
      return css`
        background: ${({theme}) => theme.colors.severity.danger};
        color: ${({theme}) => theme.colors.general.white};
        border-radius: ${({theme}) => theme.getSize(1)};

        &:hover {
          background: ${({theme}) => theme.colors.palettes.red[70][100]};
        }

        &:active {
          background: ${({theme}) => theme.colors.palettes.red[80][100]};
        }

        &:focus-visible {
          background: ${({theme}) => theme.colors.severity.danger};
          box-shadow: 0 0 0 4px ${({theme}) => theme.colors.palettes.blue[40][100]};
        }

        &:disabled {
          background: ${({theme}) => theme.colors.severity.danger};
        }
      `;
    case 'success':
      // eslint-disable-next-line eag/no-css-property
      return css`
        background: ${({theme}) => theme.colors.severity.success};
        color: ${({theme}) => theme.colors.general.white};
        border-radius: ${({theme}) => theme.getSize(1)};

        &:hover {
          background: ${({theme}) => theme.colors.palettes.green[70][100]};
        }

        &:active {
          background: ${({theme}) => theme.colors.palettes.green[80][100]};
        }

        &:focus-visible {
          background: ${({theme}) => theme.colors.severity.danger};
          box-shadow: 0 0 0 4px ${({theme}) => theme.colors.palettes.blue[40][100]};
        }

        &:disabled {
          background: ${({theme}) => theme.colors.severity.danger};
        }
      `;
    case 'dangerOutlined':
      return css`
        background: ${({theme}) => theme.colors.general.white};
        color: ${({theme}) => theme.colors.text.danger};
        border: 1px solid;
        border-color: ${({theme}) => theme.colors.severity.danger};
        border-radius: ${({theme}) => theme.getSize(1)};

        &:hover {
          background: ${({theme}) => theme.colors.general.white};
          border: 1px solid;
          border-color: ${({theme}) => theme.colors.palettes.red[80][100]};
        }

        &:active {
          background: ${({theme}) => theme.colors.general.white};
          border: 1px solid;
          border-color: ${({theme}) => theme.colors.palettes.red[90][100]};
        }

        &:focus-visible {
          background: ${({theme}) => theme.colors.general.white};
          box-shadow: 0 0 0 1px ${({theme}) => theme.colors.severity.danger};
        }

        &:disabled {
          background: ${({theme}) => theme.colors.general.white};
          border: 1px solid;
          border-color: ${({theme}) => theme.colors.severity.danger};
        }
      `;
    case 'dangerLink':
      // eslint-disable-next-line eag/no-css-property
      return css`
        background: ${({theme}) => theme.colors.general.transparent};
        color: ${({theme}) => theme.colors.text.danger};
        border-radius: 0;
        padding: 0;

        &:hover {
          background: ${({theme}) => theme.colors.general.transparent};
          text-decoration: underline;
        }

        &:active {
          background: ${({theme}) => theme.colors.general.transparent};
        }

        &:focus-visible {
          background: ${({theme}) => theme.colors.general.transparent};
          box-shadow: 0 0 0 4px ${({theme}) => theme.colors.palettes.blue[40][100]};
        }

        &:disabled {
          background: ${({theme}) => theme.colors.general.transparent};
        }
      `;
    case 'dangerGhost':
      // eslint-disable-next-line eag/no-css-property
      return css`
        background: ${({theme}) => theme.colors.general.transparent};
        color: ${({theme}) => theme.colors.text.danger};
        border-radius: ${({theme}) => theme.getSize(1)};

        &:hover {
          background: ${({theme}) => theme.colors.palettes.neutral[30][100]};
        }

        &:active {
          background: ${({theme}) => theme.colors.palettes.neutral[40][100]};
        }

        &:focus-visible {
          background: ${({theme}) => theme.colors.general.transparent};
          box-shadow: 0 0 0 4px ${({theme}) => theme.colors.palettes.blue[40][100]};
        }

        &:disabled {
          background: ${({theme}) => theme.colors.general.transparent};
        }
      `;
    default:
      // eslint-disable-next-line eag/no-css-property
      return css`
        background: ${({theme}) => theme.colors.general.accent};
        color: ${({theme}) => theme.colors.general.white};
        border-radius: ${({theme}) => theme.getSize(1)};

        &:hover {
          background: ${({theme}) => theme.colors.palettes.blue[70][100]};
        }

        &:active {
          background: ${({theme}) => theme.colors.palettes.blue[80][100]};
        }

        &:focus-visible {
          background: ${({theme}) => theme.colors.general.accent};
          box-shadow: 0 0 0 4px ${({theme}) => theme.colors.palettes.blue[40][100]};
        }

        &:disabled {
          background: ${({theme}) => theme.colors.general.accent};
        }
      `;
  }
};

const getSizeStyles = (size?: ButtonSize) => {
  switch (size) {
    case 'default':
      // eslint-disable-next-line eag/no-css-property
      return css`
        height: ${({theme}) => theme.getSize(8)};
        padding-block: 6px;
        padding-inline: ${({theme}) => theme.getSize(3)};
      `;
    case 'small':
      // eslint-disable-next-line eag/no-css-property
      return css`
        height: ${({theme}) => theme.getSize(6)};
        padding-block: 2px;
        padding-inline: ${({theme}) => theme.getSize(2)};
      `;
    default:
      // eslint-disable-next-line eag/no-css-property
      return css`
        height: ${({theme}) => theme.getSize(8)};
        padding-block: 6px;
        padding-inline: ${({theme}) => theme.getSize(3)};
      `;
  }
};

const getCombinedSizeVariantStyles = (size?: ButtonSize, variant?: ButtonVariant) => {
  if (size === 'small' && variant === 'link') {
    // eslint-disable-next-line eag/no-css-property
    return css`
      font-size: ${({theme}) => theme.fontSizes.text.xSmall};
      line-height: ${({theme}) => theme.lineHeights.text.xSmall};
      height: ${({theme}) => theme.getSize(4)};
    `;
  }
  return '';
};

interface SharedStyleProps {
  $variant?: ButtonVariant;
  $size?: ButtonSize;
  $isFullWidth?: boolean;
  $isLoading?: boolean;
}

const sharedStyles = css<SharedStyleProps>`
  ${baseStyles}
  ${({$size}) => getSizeStyles($size)}
  ${({$variant}) => getVariantStyles($variant)}
  ${({$size, $variant}) => getCombinedSizeVariantStyles($size, $variant)}

  ${(props) =>
    props.$isLoading &&
    css`
      pointer-events: none;
      overflow: hidden;
    `}

  ${(props) =>
    props.$isFullWidth &&
    css`
      min-width: 100%;
    `}
`;

const StyledButton = styled.button<SharedStyleProps>`
  ${sharedStyles}
`;

const StyledLink = styled(Link)<SharedStyleProps>`
  ${sharedStyles}
`;
