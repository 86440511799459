import {Show, Space, Text, useResponsivePropValue, Grid, GridItem} from 'platform/foundation';
import {useDateTimeFormatter} from 'platform/locale';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {parseDate} from 'shared';

import {CommentExpandable} from '../../components/CommentExpandable/CommentExpandable';
import {SectionNew} from '../../components/SectionNew/SectionNew';
import {useGetOnlineOfferData} from '../../hooks/useGetOnlineOfferData';
import {getDataForDetails} from '../../utils/getDataForDetails';
import {DetailsColumn} from './DetailsColumn';

export function Details() {
  const {vehicle, alphaCatalogue, advertisementDescription} = useGetOnlineOfferData();
  const gridColumns = useResponsivePropValue([1, 2]);
  const formatDateTime = useDateTimeFormatter();
  if (!vehicle) {
    return null;
  }

  const data = getDataForDetails(vehicle, alphaCatalogue);
  if (!data) {
    return null;
  }

  return (
    <SectionNew id="DETAILS" heading="Detaily" isWithBreakLine>
      <Grid columns={gridColumns} spacing={[0, 8]}>
        <GridItem>
          <DetailsColumn
            data={[
              {label: 'VIN', value: data.vin},
              {
                label: 'První registrace',
                value: data.firstRegistration
                  ? formatDateTime('dateShort', parseDate(data.firstRegistration))
                  : '',
              },
              {label: 'Země původu', value: data.countryOfOrigin},
              {label: 'Typ barvy', value: data.typeOfColour},
              {label: 'Název barvy výrobce', value: data.manufacturerColourName},
              {
                label: 'Barva karoserie',
                value: data.exteriorColour?.name,
                colorCode: data.exteriorColour.code ?? undefined,
              },
              {
                label: 'Barva interiéru',
                value: data.interiorColour.name,
                colorCode: data.interiorColour.code ?? undefined,
              },
              {label: 'Materiál interiéru', value: data.interiorMaterial},
              {label: 'Počet míst', value: data.numberOfSeats},
              {label: 'Počet dveří', value: data.numberOfDoors},
              {label: 'Počet klíčů', value: data.numberOfKeys},
              {label: 'Počet majitelů', value: data.numberOfOwners},
            ]}
          />
        </GridItem>
        <GridItem>
          <DetailsColumn
            data={[
              {label: 'Typ hybridu', value: data.hybridType},
              {label: 'Sekundární palivo', value: data.secondaryFuel},
              {label: 'Emisní třída', value: data.emissionClass},
              {label: 'Spotřeba - kombinovaná', value: data.fuelUsageCombined},
              {label: 'Spotřeba elektřiny - kombinovaná', value: data.electricalUsageCombined},
              {label: 'Výkon spalovacího motoru (kW)', value: data.enginePower},
              {label: 'Výkon elektrického motoru (kW)', value: data.electricalEnginePower},
              {label: 'Zdvihový moment (ccm)', value: data.engineDisplacement},
              {label: 'Počet válců', value: data.numberOfPistons},
              {label: 'Počet převodových stupňů', value: data.numberOfGears},
            ]}
          />
        </GridItem>
      </Grid>
      <Space vertical={7} />
      <Show when={isNotNilOrEmpty(advertisementDescription)}>
        <Text size="large" alternative>
          Popis prodejce
        </Text>
        <Space vertical={2} />
        <CommentExpandable hideCommentFlag comment={advertisementDescription!} />
      </Show>
    </SectionNew>
  );
}
