import {Heading, HStack, Text, ThemeIconKey, VStack} from 'platform/foundation';

import {AvatarIcon} from '../../components/AvatarIcon/AvatarIcon';

type TechnicalParametersItemProps = {
  icon: ThemeIconKey;
  title: string;
  value: string;
};

export const TechnicalParametersItem = (props: TechnicalParametersItemProps) => (
  <HStack align="center" spacing={4}>
    <AvatarIcon icon={props.icon} />
    <VStack spacing={1}>
      <Text color="secondary" size="xSmall">
        {props.title}
      </Text>
      <Heading size={4}>{props.value}</Heading>
    </VStack>
  </HStack>
);
