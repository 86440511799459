import {Nullish} from 'shared';

import {AlphaCatalogue} from '../../../api/digitalCertificateApi';
import {VehicleV2ResponseBody} from '../../../api/types/onlineOfferTypes';
import {getAlphaCatalogueLabel} from '../../../utils/getAlphaCatalogueLabel';

export function getSpecification(
  vehicle: VehicleV2ResponseBody | Nullish,
  alphaCatalogue: AlphaCatalogue | Nullish
) {
  if (!vehicle || !alphaCatalogue) {
    return null;
  }

  const emmisionClass = getAlphaCatalogueLabel({
    vehicleType: vehicle.type,
    propertyName: 'emission_class',
    key: vehicle.emissionClass,
    alphaCatalogue,
  });

  const bodywork = getAlphaCatalogueLabel({
    vehicleType: vehicle.type,
    propertyName: 'car_style',
    key: vehicle.bodyStyle,
    alphaCatalogue,
  });

  const fuel = getAlphaCatalogueLabel({
    vehicleType: vehicle.type,
    propertyName: 'fuel_type',
    key: vehicle.fuelType,
    alphaCatalogue,
  });

  const transmission = getAlphaCatalogueLabel({
    vehicleType: vehicle.type,
    propertyName: 'transmission',
    key: vehicle.transmission,
    alphaCatalogue,
  });

  const differential = getAlphaCatalogueLabel({
    vehicleType: vehicle.type,
    propertyName: 'drive',
    key: vehicle.drive,
    alphaCatalogue,
  });

  return {
    vin: vehicle.vin,
    yearOfManufacture: vehicle.manufacturedOn,
    mileage: vehicle.mileage,
    numberOfSeat: vehicle.seatCount,
    power: vehicle.power,
    co2Emissions: vehicle.carbonDioxideEmission,
    differential,
    euroClass: emmisionClass,
    fuel,
    transmission,
    bodywork,
  };
}
