import {BoxProps} from 'platform/foundation';

import {RequiredTestIdProps} from 'shared';

import {FeatureItemFlag} from '../FeatureItemFlag/FeatureItemFlag';

export interface FeatureItemProps extends RequiredTestIdProps {
  title: string;
  isHighlighted?: boolean;
  boxProps?: BoxProps;
}
export function FeatureItem(props: FeatureItemProps) {
  return (
    <FeatureItemFlag
      label={props.title}
      isSubtle
      isTransparent={!props.isHighlighted}
      isBoldText
      colorScheme="purple"
      data-testid={props['data-testid']}
    />
  );
}
