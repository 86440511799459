import {APPLICATION_NAME} from '@online-offer/config';
import {Box, Center, Show, Space, Stack, VStack} from 'platform/foundation';

import {Helmet, HelmetProvider} from 'react-helmet-async';
import {Route, Routes} from 'react-router-dom';

import {Details} from './(sections)/Details/Details';
import {Error} from './(sections)/Error/Error';
import {Features} from './(sections)/Features/Features';
import {Hero} from './(sections)/Hero/Hero';
import {SellerCard} from './(sections)/SellerCard/SellerCard';
import {Sidebar} from './(sections)/Sidebar/Sidebar';
import {Specification} from './(sections)/Specification/Specification';
import {TechnicalParameters} from './(sections)/TechnicalParameters/TechnicalParameters';
import {ApplicationInitializeBoundary} from './components/ApplicationInitializeBoundary/ApplicationInitializeBoundary';
import {Footer} from './components/Footer/Footer';
import {Navigation} from './components/Navigation/Navigation';
import i18n from './i18n/i18n';

export function App() {
  return (
    <Routes>
      <Route element={<ApplicationInitializeBoundary />}>
        <Route path=":offerId/" element={<OnlineOffer />} />
        <Route path=":offerId/pdf" element={<OnlineOfferPrint />} />
        <Route path="*" element={<Error />} />
      </Route>
    </Routes>
  );
}
function OnlineOffer() {
  return (
    <HelmetProvider>
      <Helmet titleTemplate={`%s • ${APPLICATION_NAME}`} htmlAttributes={{lang: i18n.language}}>
        <meta name="description" content={APPLICATION_NAME} />
      </Helmet>

      <Navigation />

      <Hero />

      <Space vertical={[6, 6, 11]} />

      <Center>
        <Box flexGrow={1} paddingHorizontal={[3, 8, 20, 20]} maxWidth={440}>
          <Stack direction={['column-reverse', 'column-reverse', 'row']} spacing={8} width="100%">
            <VStack width="100%">
              <Specification />
              <Details />
              <Features />
              {/*<Certificate data={{digitalCertificateVehicleId: auditId, ratings}} />*/}
              <TechnicalParameters />
              <Show onMobile onTablet>
                <SellerCard />
              </Show>
            </VStack>
            <VStack spacing={[6, 6, 8]} width={['100%', '100%', 90]} shrink={0}>
              <Sidebar />
            </VStack>
          </Stack>
        </Box>
      </Center>
      <Footer />
    </HelmetProvider>
  );
}

function OnlineOfferPrint() {
  // const {vehicleAudit} = useGetOnlineOfferData();
  // const locale = useLocale();
  // const wheelsAndTiresData = useGetDataForWheelsAndTires(vehicleAudit, locale);

  // const sections = getActiveSections(vehicleAudit?.auditStructure?.categories);

  return null;
  // return (
  //   <HelmetProvider>
  //     <Helmet titleTemplate={`%s • ${APPLICATION_NAME}`} htmlAttributes={{lang: i18n.language}}>
  //       <meta name="description" content={APPLICATION_NAME} />
  //     </Helmet>
  //
  //     <Box maxWidth={278} width={278} backgroundColor="fill.white">
  //       <HeroPrint />
  //       <SpecificationPrint />
  //       <Show when={isSectionActive('EXTERIOR', sections)}>
  //         <ExteriorPrint />
  //       </Show>
  //       <Show when={isSectionActive('INTERIOR', sections)}>
  //         <InteriorPrint />
  //       </Show>
  //       <Show when={isSectionActive('TESTDRIVE', sections)}>
  //         <TestDrivePrint />
  //       </Show>
  //       <Show
  //         when={
  //           isSectionActive('WHEELS_AND_TIRES', sections) &&
  //           !wheelsAndTiresData.sectionConfig.isHidden
  //         }
  //       >
  //         <WheelsAndTiresPrint {...wheelsAndTiresData} />
  //       </Show>
  //       <Show when={isSectionActive('MECHANICS', sections)}>
  //         <MechanicsPrint />
  //       </Show>
  //       <Show when={isSectionActive('PHOTODOCUMENTATION', sections)}>
  //         <PhotoDocumentationPrint />
  //       </Show>
  //       <Show when={isSectionActive('VEHICLE_DOCUMENTATION', sections)}>
  //         <DocumentationPrint />
  //       </Show>
  //       <Show when={isSectionActive('SERVICE_HISTORY', sections)}>
  //         <ServiceHistoryPrint />
  //       </Show>
  //     </Box>
  //   </HelmetProvider>
  // );
}
