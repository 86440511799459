import {configureStore} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/query';

import {digitalCertificateApi} from './api/digitalCertificateApi';
import {onlineOfferApi} from './api/onlineOfferApi';

export const store = configureStore({
  reducer: {
    [digitalCertificateApi.reducerPath]: digitalCertificateApi.reducer,
    [onlineOfferApi.reducerPath]: onlineOfferApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(digitalCertificateApi.middleware, onlineOfferApi.middleware),
});

setupListeners(store.dispatch);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
