import {useParams} from 'react-router-dom';

import {AlphaCatalogue, useGetAllApiEnumsQuery} from '../api/digitalCertificateApi';
import {useGetBusinessCaseOnlineOfferQuery} from '../api/onlineOfferApi';
import {BusinessCaseOnlineOfferResponseBody} from '../api/types/onlineOfferTypes';
import {queryParams} from '../consts/queryParams';
import {i18n} from '../i18n/i18n';
import {getWorkspaceFromUri} from '../utils/getWorkspaceFromUri';
import {useQueryState} from '../utils/useQueryState';

const {workspace} = getWorkspaceFromUri();

type GetOnlineOfferDataContext = Partial<BusinessCaseOnlineOfferResponseBody> & {
  alphaCatalogue: AlphaCatalogue | undefined;
};

export function useGetOnlineOfferData(): GetOnlineOfferDataContext {
  const [language] = useQueryState(queryParams.LNG);
  const params = useParams<{offerId: string}>();

  const {data: onlineOfferData} = useGetBusinessCaseOnlineOfferQuery({
    offerId: params.offerId ?? '',
    workspace: workspace ?? '',
  });

  const {data: alphaCatalogue} = useGetAllApiEnumsQuery({lang: language ?? i18n.resolvedLanguage});

  return {...onlineOfferData, alphaCatalogue} ?? {alphaCatalogue};
}
