import {Nullish} from 'shared';

import {AlphaCatalogue} from '../api/digitalCertificateApi';

type AlphaCatalogueLabelParams = {
  outerPropertyName: string;
  innerPropertyName: string;
  alphaCatalogue: AlphaCatalogue;
  vehicleType: string | Nullish;
  key: string | Nullish;
};

export const getAlphaCataloguePropertyValue = (
  params: AlphaCatalogueLabelParams
): string | boolean | Nullish =>
  params.alphaCatalogue
    ?.find(
      (property) =>
        property.vehicle_type === params.vehicleType &&
        property.property_name === params.outerPropertyName
    )
    ?.keys?.find((propertyKey) => propertyKey.const_key === params.key)
    ?.properties?.find((property) => property.name === params.innerPropertyName)?.value ??
  params.key;
