import {Nullish} from 'shared';

import {VehicleV2ResponseBody} from '../api/types/onlineOfferTypes';

export type TechnicalParamsDataBundle = {
  dimensions: {
    width: string | undefined;
    height: string | undefined;
    length: string | undefined;
  };
  details: {
    trunkSpace: string | undefined;
    weight: string | undefined;
    operatingWeight: string | undefined;
  };
};

export const getDataForTechnicalParameters = (
  vehicle: VehicleV2ResponseBody | Nullish
): TechnicalParamsDataBundle => ({
  dimensions: {
    width: vehicle?.dimensions?.width ? String(vehicle.dimensions.width) : undefined,
    height: vehicle?.dimensions?.height ? String(vehicle.dimensions.height) : undefined,
    length: vehicle?.dimensions?.length ? String(vehicle.dimensions.length) : undefined,
  },
  details: {
    trunkSpace: vehicle?.trunkSpace ? String(vehicle.trunkSpace) : undefined,
    weight: vehicle?.weight ? String(vehicle.weight) : undefined,
    operatingWeight: vehicle?.operatingWeight ? String(vehicle.operatingWeight) : undefined,
  },
});
