import {Nullish} from 'shared';

import {AlphaCatalogue} from '../api/digitalCertificateApi';
import {VehicleV2ResponseBody} from '../api/types/onlineOfferTypes';
import {getAlphaCatalogueLabel} from '../utils/getAlphaCatalogueLabel';
import {getAlphaCataloguePropertyValue} from './getAlphaCataloguePropertyValue';

export interface DetailsDataBundle {
  vin: string | Nullish;
  firstRegistration: string | Nullish;
  countryOfOrigin: string | Nullish;
  typeOfColour: string | Nullish;
  manufacturerColourName: string | Nullish;
  exteriorColour: {
    name: string | Nullish;
    code: string | Nullish;
  };
  interiorColour: {
    name: string | Nullish;
    code: string | Nullish;
  };
  interiorMaterial: string | Nullish;
  numberOfSeats: number | Nullish;
  numberOfDoors: string | Nullish;
  numberOfKeys: number | Nullish;
  numberOfOwners: number | Nullish;
  hybridType: string | Nullish;
  secondaryFuel: string | Nullish;
  emissionClass: string | Nullish;
  fuelUsageCombined: string | Nullish;
  electricalUsageCombined: string | Nullish;
  enginePower: number | Nullish;
  electricalEnginePower: string | Nullish;
  engineDisplacement: number | Nullish;
  numberOfPistons: number | Nullish;
  numberOfGears: number | Nullish;
}

export const getDataForDetails = (
  vehicle: VehicleV2ResponseBody | Nullish,
  alphaCatalogue: AlphaCatalogue | Nullish
): DetailsDataBundle | null => {
  if (!vehicle || !alphaCatalogue) {
    return null;
  }
  const countryOfOrigin = getAlphaCatalogueLabel({
    vehicleType: vehicle.type,
    propertyName: 'country',
    key: vehicle.originCountry,
    alphaCatalogue,
  });
  const typeOfColour = getAlphaCatalogueLabel({
    vehicleType: vehicle.type,
    propertyName: 'color_type',
    key: vehicle.exteriorColorSpecification,
    alphaCatalogue,
  });
  const manufacturerColourName = getAlphaCatalogueLabel({
    vehicleType: vehicle.type,
    propertyName: 'body_color',
    key: vehicle.manufacturerColorName,
    alphaCatalogue,
  });
  const exteriorColourName = getAlphaCatalogueLabel({
    vehicleType: vehicle.type,
    propertyName: 'body_color',
    key: vehicle.exteriorColor?.value,
    alphaCatalogue,
  });
  const exteriorColorCode = getAlphaCataloguePropertyValue({
    vehicleType: vehicle.type,
    outerPropertyName: 'body_color',
    innerPropertyName: 'color_code',
    key: vehicle.exteriorColor?.value,
    alphaCatalogue,
  });
  const interiorColourName = getAlphaCatalogueLabel({
    vehicleType: vehicle.type,
    propertyName: 'interior_color',
    key: vehicle.interiorColor?.value,
    alphaCatalogue,
  });
  const interiorColorCode = getAlphaCataloguePropertyValue({
    vehicleType: vehicle.type,
    outerPropertyName: 'interior_color',
    innerPropertyName: 'color_code',
    key: vehicle.interiorColor?.value,
    alphaCatalogue,
  });
  const interiorMaterial = getAlphaCatalogueLabel({
    vehicleType: vehicle.type,
    propertyName: 'interior_material',
    key: vehicle.interiorMaterial,
    alphaCatalogue,
  });
  const numberOfDoors = getAlphaCatalogueLabel({
    vehicleType: vehicle.type,
    propertyName: 'door_count',
    key: vehicle.doorCountCategory,
    alphaCatalogue,
  });
  const hybridType = getAlphaCatalogueLabel({
    vehicleType: vehicle.type,
    propertyName: 'hybrid_type',
    key: vehicle.hybridType,
    alphaCatalogue,
  });
  const secondaryFuel = getAlphaCatalogueLabel({
    vehicleType: vehicle.type,
    propertyName: 'fuel_type',
    key: vehicle.fuelType,
    alphaCatalogue,
  });
  const emissionClass = getAlphaCatalogueLabel({
    vehicleType: vehicle.type,
    propertyName: 'emission_class',
    key: vehicle.emissionClass,
    alphaCatalogue,
  });
  const fuelUsageCombinedRatio = getAlphaCatalogueLabel({
    vehicleType: vehicle.type,
    propertyName: 'fuel_unit',
    key: 'FUEL_UNIT_L100KM',
    alphaCatalogue,
  });
  const fuelUsageCombined = vehicle.fuelConsumptionCombined
    ? `${vehicle.fuelConsumptionCombined} ${fuelUsageCombinedRatio}`
    : null;
  const electricalUsageCombinedRatio = getAlphaCatalogueLabel({
    vehicleType: vehicle.type,
    propertyName: 'fuel_unit',
    key: 'FUEL_UNIT_KWH100KM',
    alphaCatalogue,
  });
  const electricalUsageCombined = vehicle.powerConsumptionCombined
    ? `${vehicle.powerConsumptionCombined} ${electricalUsageCombinedRatio}`
    : null;

  return {
    vin: vehicle.vin,
    firstRegistration: vehicle.firstRegistrationOn,
    countryOfOrigin,
    typeOfColour,
    manufacturerColourName,
    exteriorColour: {
      name: exteriorColourName,
      code: typeof exteriorColorCode === 'boolean' ? null : exteriorColorCode,
    },
    interiorColour: {
      name: interiorColourName,
      code: typeof interiorColorCode === 'boolean' ? null : interiorColorCode,
    },
    interiorMaterial,
    numberOfSeats: vehicle.seatCount,
    numberOfDoors,
    numberOfKeys: vehicle.primaryKeyCount,
    numberOfOwners: vehicle.ownerCount,
    hybridType,
    secondaryFuel,
    emissionClass,
    fuelUsageCombined,
    electricalUsageCombined,
    enginePower: vehicle.power,
    electricalEnginePower: vehicle.powerElectricEngine,
    engineDisplacement: vehicle.engineVolume,
    numberOfPistons: vehicle.cylinderCount,
    numberOfGears: vehicle.gearCount,
  };
};
