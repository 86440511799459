import {Separator} from 'platform/components';
import {Heading, VStack} from 'platform/foundation';
import {useFormatCurrency} from 'platform/locale';

import {isNilOrEmpty} from 'ramda-adjunct';

import {EMPTY_PLACEHOLDER, Nullish} from 'shared';

import {MoneyResponseBody} from '../../api/types/money';
import {CardOo} from '../../components/CardOo/CardOo';
import {useGetOnlineOfferData} from '../../hooks/useGetOnlineOfferData';
import {ReservationRow} from './ReservationRow';

type ReservationCardType = {
  isExpired?: boolean;
};

export function ReservationCard(props: ReservationCardType) {
  const {
    upsell,
    totalPriceWithVat,
    totalPriceWithoutVat,
    totalDiscountPriceWithVat,
    totalDiscountPriceWithoutVat,
    vehiclePriceWithVat,
    vehiclePriceWithoutVat,
    originalCarPriceWithVat,
    originalCarPriceWithoutVat,
  } = useGetOnlineOfferData();

  const formatCurrency = useFormatCurrency();

  const formatPrice = (price: MoneyResponseBody | Nullish) =>
    price?.amount ? formatCurrency(parseFloat(price.amount), price.currency) : EMPTY_PLACEHOLDER;

  return (
    <CardOo backgroundColor="semantic.bg.subtle">
      <VStack width="100%" spacing={5}>
        <VStack spacing={2}>
          <ReservationRow
            label="Cena celkem"
            value={<Heading size={3}>{formatPrice(totalPriceWithVat)}</Heading>}
            labelProps={{alternative: true}}
          />
          <ReservationRow label="Cena celkem bez DPH" value={formatPrice(totalPriceWithoutVat)} />
        </VStack>
        {/* TODO: uncomment when ready on BE(no task yet) */}
        {/*<VStack spacing={2}>*/}
        {/*  <Button*/}
        {/*    leftIcon="good"*/}
        {/*    title="Mám zájem o vůz"*/}
        {/*    data-testid="reservationCard-resrve"*/}
        {/*    variant="primary"*/}
        {/*    onClick={() => {}}*/}
        {/*  />*/}
        {/*  <Button*/}
        {/*    leftIcon={props.isExpired ? 'specification/year' : 'navigation/cancel'}*/}
        {/*    leftIconSize={4}*/}
        {/*    title={props.isExpired ? 'Rezervovat vůz' : 'Zrušit rezervaci'}*/}
        {/*    data-testid="reservationCard-cancel"*/}
        {/*    variant="inverted"*/}
        {/*    onClick={() => {}}*/}
        {/*  />*/}
        {/*</VStack>*/}
        <Separator spacing={0} />
        <VStack spacing={2}>
          <ReservationRow
            label="Cena vozu"
            labelProps={{alternative: true}}
            value={<Heading size={4}>{formatPrice(vehiclePriceWithVat)}</Heading>}
          />
          <ReservationRow label="Cena vozu bez DPH" value={formatPrice(vehiclePriceWithoutVat)} />
          <ReservationRow
            label="Původní cena vozu"
            value={formatPrice(originalCarPriceWithVat ?? originalCarPriceWithoutVat)}
            valueProps={{color: 'danger', strikethrough: true}}
          />
          <ReservationRow
            label="Zákaznická sleva"
            value={formatPrice(totalDiscountPriceWithVat ?? totalDiscountPriceWithoutVat)}
            valueProps={{color: 'success'}}
          />
        </VStack>
        <Separator spacing={0} />
        <VStack spacing={2}>
          <ReservationRow label="Doplňkový prodej" labelProps={{alternative: true}} />
          {upsell?.map((singleItem) => {
            if (
              isNilOrEmpty(singleItem.name) ||
              (isNilOrEmpty(singleItem.sellingPrice?.withVat) &&
                isNilOrEmpty(singleItem.sellingPrice?.withVat))
            ) {
              return null;
            }
            return (
              <ReservationRow
                key={singleItem.id}
                label={singleItem.name!}
                value={formatPrice(
                  singleItem.sellingPrice?.withVat ?? singleItem.sellingPrice?.withoutVat
                )}
              />
            );
          })}
        </VStack>
        {/* TODO: financovanie - not planned in online-offer v1 */}
        {/*<CardOo backgroundColor="general.white" radius="small">*/}
        {/*  <ReservationRow*/}
        {/*    label="Při financování od"*/}
        {/*    labelProps={{alternative: true}}*/}
        {/*    value={<Heading size={4}>26 786 Kč/měs</Heading>}*/}
        {/*  />*/}
        {/*  <Separator spacing={2} />*/}
        {/*  <ReservationRow label="Při financování od" value="60 měsíců (5 let)" />*/}
        {/*</CardOo>*/}
      </VStack>
    </CardOo>
  );
}
