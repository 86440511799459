import {EMPTY_PLACEHOLDER, Nullish} from 'shared';

import {CustomerResponseBodyV2} from '../api/types/onlineOfferTypes';
import {getNaturalPersonFullName} from './getNaturalPersonFullName';
import {isCustomerNaturalPerson} from './isCustomerNaturalPerson';
import {placeholderFallback} from './placeholderFallback';

export function getCustomerName(customer: CustomerResponseBodyV2 | Nullish): string {
  if (!customer) {
    return EMPTY_PLACEHOLDER;
  }

  if (isCustomerNaturalPerson(customer)) {
    return placeholderFallback(getNaturalPersonFullName(customer?.foundingPerson));
  }

  return placeholderFallback(customer.businessInfo?.businessInfo?.tradeName);
}
