import {DropdownContainer} from './components/DropdownContainer';
import {DropdownProps} from './types/DropdownProps';

export function Dropdown(props: DropdownProps) {
  return (
    <DropdownContainer
      isOpen={props.isOpen}
      onOpen={props.onOpen}
      onClose={props.onClose}
      dropdownControl={props.dropdownControl}
      isHeightLimited={props.isHeightLimited}
      placement={props.placement}
      data-testid={props['data-testid']}
      isFocusManagementDisabled={props.isFocusManagementDisabled}
    >
      {props.children}
    </DropdownContainer>
  );
}
