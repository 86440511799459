import {APPLICATION_NAME} from '@online-offer/config';
import {
  Box,
  Center,
  getValueByDevice,
  Heading,
  Hide,
  HStack,
  Show,
  Space,
  Text,
  useDevice,
  VStack,
} from 'platform/foundation';

import {useGetOnlineOfferData} from '../../hooks/useGetOnlineOfferData';
import i18n from '../../i18n/i18n';
import {getCustomerName} from '../../utils/getCustomerName';
import {getWorkspaceCustomization} from '../../utils/getWorkspaceCustomization';
import {Button} from '../Button/Button';
import {ButtonIcon} from '../ButtonIcon/ButtonIcon';

export function Navigation() {
  const {logo: applicationLogo} = getWorkspaceCustomization();

  const {activeReservation, customer, vehicle} = useGetOnlineOfferData();

  const device = useDevice();

  const customerName = activeReservation?.customer?.name ?? getCustomerName(customer);

  const onShare = () =>
    navigator.share({
      title: `${vehicle?.title} • ${APPLICATION_NAME}`,
      url: window.location.href,
    });

  return (
    <>
      <Box
        position={getValueByDevice(device, 'sticky', 'static', 'static', 'static')}
        top={0}
        backgroundColor="general.white"
        zIndex="MENU_BAR"
        data-testid="navigationContainer"
      >
        <Center justify="center" width="100%">
          <Box
            flexGrow={1}
            maxWidth={432}
            paddingTop={[2, 4, 4, 6]}
            paddingBottom={[0, 4, 4, 6]}
            paddingHorizontal={[3, 8, 8, 20]}
          >
            <HStack align="center" justify="space-between">
              {applicationLogo}
              <Hide onMobile>
                <Box paddingLeft={8}>
                  <VStack>
                    <Text size="small" color="secondary">
                      Obchodní nabídka {activeReservation?.businessCase?.code} pro:
                    </Text>
                    <Heading size={4}>{customerName}</Heading>
                  </VStack>
                </Box>
              </Hide>
              <Space horizontal={4} fillAvailable />
              <HStack spacing={2}>
                <Show when={navigator.share}>
                  <Show onMobile>
                    <ButtonIcon
                      data-testid="shareButton"
                      variant="outline"
                      icon="share"
                      onClick={onShare}
                      size="small"
                    />
                  </Show>
                  <Hide onMobile>
                    <Button
                      data-testid="shareButton"
                      variant="outline"
                      title={i18n.t('share')}
                      leftIcon="share"
                      onClick={onShare}
                    />
                  </Hide>
                </Show>
              </HStack>
            </HStack>
            <Show onMobile>
              <Box paddingTop={3}>
                <Show onMobile>
                  <VStack>
                    <Text size="small" color="secondary">
                      Obchodní nabídka {activeReservation?.businessCase?.code} pro:
                    </Text>
                    <Heading size={4}>{customerName}</Heading>
                  </VStack>
                </Show>
              </Box>
            </Show>
          </Box>
        </Center>
      </Box>
    </>
  );
}
