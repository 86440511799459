import {Box, Heading, VStack} from 'platform/foundation';
import {Lightbox, useLightbox} from 'platform/lightbox';

import {Helmet} from 'react-helmet-async';

import {CarouselSwiper} from '../../components/CarouselSwiper/CarouselSwiper';
import {useGetOnlineOfferData} from '../../hooks/useGetOnlineOfferData';

export function Hero() {
  const {saleVehiclePhotos, vehicle} = useGetOnlineOfferData();

  const [heroLightboxControls, {onOpenBySlide}] = useLightbox('hero');

  const allPhotos =
    saleVehiclePhotos?.map((photo) => ({
      type: 'image' as const,
      id: photo.id,
      url: photo.url,
      resizeUrl: photo.url,
    })) ?? [];

  return (
    <section id="hero">
      <Helmet>
        <title>{vehicle?.title}</title>
      </Helmet>

      <Box width="100%" overflow="hidden" backgroundColor="fill.white">
        <VStack align="center">
          <Box
            width="100%"
            maxWidth={432}
            paddingTop={[4, 3, 3, 3]}
            paddingBottom={[4, 6, 6, 8]}
            paddingHorizontal={[3, 8, 8, 20]}
          >
            <Heading size={1}>{vehicle?.title}</Heading>
          </Box>
          <Box width="100%">
            <CarouselSwiper
              images={allPhotos}
              onInspectImage={(slide) => onOpenBySlide(slide, allPhotos)}
            />
          </Box>
          <Lightbox
            data-testid="hero"
            controls={heroLightboxControls}
            images={saleVehiclePhotos?.map((photo) => ({
              type: 'image',
              id: photo.id,
              url: photo.url,
              resizeUrl: photo.url,
              fileName: photo.id,
            }))}
          />
        </VStack>
      </Box>
    </section>
  );
}
