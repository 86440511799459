import {Separator} from 'platform/components';
import {Heading, HStack, Icon, Show, Space, Text, VStack} from 'platform/foundation';
import {formatPhoneNumber} from 'platform/locale';

import {EMPTY_PLACEHOLDER} from 'shared';

import {Button} from '../../components/Button/Button';
import {CardOo} from '../../components/CardOo/CardOo';
import {useGetOnlineOfferData} from '../../hooks/useGetOnlineOfferData';
import {placeholderFallback} from '../../utils/placeholderFallback';

export function SellerCard() {
  const {owner} = useGetOnlineOfferData();
  if (!owner) {
    return null;
  }
  const ownerName = [owner.firstName, owner.lastName].filter(Boolean).join(' ');
  return (
    <CardOo backgroundColor="semantic.bg.subtle">
      <VStack width="100%">
        <HStack width="100%" spacing={5}>
          {/* TODO: offer owner photo - not planned in online-offer v1 */}
          {/*<VStack width="100%" maxWidth={18}>*/}
          {/*  <AdaptiveImageDcNew ratio="1/1" fit="contain" url="./assets/images/person.png" />*/}
          {/*</VStack>*/}
          <VStack width="100%" spacing={2}>
            <Heading size={4}>{placeholderFallback(ownerName)}</Heading>
            <Text size="xSmall" color="secondary">
              Prodejce
            </Text>
            <Separator spacing={0} />
            <HStack width="100%" spacing={2}>
              <Icon size={5} value="social/telephone" />
              <Text size="xSmall">
                {owner.phoneNumber
                  ? formatPhoneNumber(
                      `${owner.phoneNumber.prefix + owner.phoneNumber.number}`,
                      owner.phoneNumber.countryCode
                    )
                  : EMPTY_PLACEHOLDER}
              </Text>
            </HStack>
            <HStack width="100%" spacing={2}>
              <Icon size={5} value="social/email" />
              <Text size="xSmall">{placeholderFallback(owner.email)}</Text>
            </HStack>
          </VStack>
        </HStack>
        <Show when={owner.phoneNumber}>
          <Space vertical={5} />
          <VStack maxWidth={[90, 90, '100%']} width="100%">
            <Button
              onClick={() => {
                if (owner?.phoneNumber) {
                  window.open(`tel:${owner.phoneNumber.prefix + owner.phoneNumber.number}`);
                }
              }}
              leftIcon="notification/phone"
              leftIconSize={4}
              title="Zavolejte mi"
              data-testid="sellerCard-call"
              variant="inverted"
            />
          </VStack>
        </Show>
      </VStack>
    </CardOo>
  );
}
