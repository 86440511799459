import {Box, Center, Icon, ThemeIconKey, useDevice} from 'platform/foundation';
import {match} from 'ts-pattern';

import {always} from 'ramda';

type AvatarIconProps = {
  icon: ThemeIconKey;
};

export function AvatarIcon(props: AvatarIconProps) {
  const device = useDevice();
  const iconSize = match(device)
    .with('mobile', always(5 as const))
    .otherwise(always(6 as const));

  return (
    <Box
      backgroundColor="accent.lightest"
      borderRadius="small"
      minHeight={[8, 10]}
      minWidth={[8, 10]}
      height={[8, 10]}
      width={[8, 10]}
    >
      <Center height="100%">
        <Icon value={props.icon} size={iconSize} color="accent.darker" />
      </Center>
    </Box>
  );
}
