import {createApi} from '@reduxjs/toolkit/query/react';

import {dmsBaseQuery} from './dmsBaseQuery';
import {
  GetBusinessCaseOnlineOfferApiArg,
  GetBusinessCaseOnlineOfferApiResponse,
} from './types/onlineOfferTypes';

export const onlineOfferApi = createApi({
  baseQuery: dmsBaseQuery,
  reducerPath: 'onlineOfferApi',
  tagTypes: [],
  endpoints: (build) => ({
    getBusinessCaseOnlineOffer: build.query<
      GetBusinessCaseOnlineOfferApiResponse,
      GetBusinessCaseOnlineOfferApiArg
    >({
      query: (queryArg) => ({
        url: `/dms/v1/business-case/online-offer/${queryArg.offerId}/data`,
        headers: {'X-Workspace': queryArg.workspace},
      }),
    }),
  }),
});

export const {useGetBusinessCaseOnlineOfferQuery, useLazyGetBusinessCaseOnlineOfferQuery} =
  onlineOfferApi;
