import {Hide, Show} from 'platform/foundation';

import {useEffect, useState} from 'react';

import {isNotNil} from 'ramda';

import {Nullish, parseDate} from 'shared';

import {useGetOnlineOfferData} from '../../hooks/useGetOnlineOfferData';
import {ReservationCard} from '../ReservationCard/ReservationCard';
import {SellerCard} from '../SellerCard/SellerCard';
import {TimerCard, TimerState, TimerStateType} from '../TimerCard/TimerCard';

const getTimerState = ({
  days,
  hours,
  minutes,
  seconds,
}: {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}): TimerState => {
  if (days === 0 && hours < 2) {
    if (hours === 0 && minutes === 0 && seconds === 0) {
      return 'expired';
    } else {
      return 'soon_expired';
    }
  }
  return 'reserved';
};

const getTime = (deadline: string | Nullish) => {
  if (!deadline) {
    return null;
  }
  const time = parseDate(deadline).getTime() - new Date().getTime();
  return {
    days: Math.floor(time / (1000 * 60 * 60 * 24)),
    hours: Math.floor((time / (1000 * 60 * 60)) % 24),
    minutes: Math.floor((time / 1000 / 60) % 60),
    seconds: Math.floor((time / 1000) % 60),
  };
};

export function Sidebar() {
  const {activeReservation} = useGetOnlineOfferData();
  const initialTime = getTime(activeReservation?.reservedUntil);
  const [timer, setTimer] = useState<TimerStateType | null>(
    initialTime
      ? {
          ...initialTime,
          state: getTimerState(initialTime),
        }
      : null
  );

  useEffect(() => {
    const interval = setInterval(() => {
      const time = getTime(activeReservation?.reservedUntil);
      if (!time) {
        return;
      }
      const timerState = getTimerState(time);
      setTimer({...time, state: timerState});
      if (time.days === 0 && time.hours === 0 && time.minutes === 0 && time.seconds === 0) {
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [activeReservation?.reservedUntil]);

  return (
    <>
      <Show when={isNotNil(timer)}>
        <TimerCard timer={timer!} />
      </Show>
      <ReservationCard isExpired={timer?.state === 'expired'} />
      <Hide onMobile onTablet>
        <SellerCard />
      </Hide>
    </>
  );
}
