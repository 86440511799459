import {Grid, GridItem, Heading, Show, Space, Text, VStack} from 'platform/foundation';
import {useLocale} from 'platform/locale';

import {isNotNilOrEmpty} from 'ramda-adjunct';

import {Nullish} from 'shared';

import {CommentExpandable} from '../../components/CommentExpandable/CommentExpandable';
import {FeatureItem} from '../../components/FeatureItem/FeatureItem';
import {SectionNew} from '../../components/SectionNew/SectionNew';
import {useGetOnlineOfferData} from '../../hooks/useGetOnlineOfferData';
import i18n from '../../i18n/i18n';
import {CategorizedFeature, getFeatures} from '../../utils/getFeatures';
import {placeholderFallback} from '../../utils/placeholderFallback';

export function Features() {
  const {vehicle, alphaCatalogue} = useGetOnlineOfferData();
  const locale = useLocale();
  const features = getFeatures({
    vehicle,
    alphaCatalogue,
    language: locale.language,
  });

  const shouldShowFeatures = features.length > 0;
  const shouldShowAdditionalInformation = isNotNilOrEmpty(vehicle?.featuresByManufacturer);

  const renderCategory = (category: CategorizedFeature | Nullish) => {
    if (!category) {
      return null;
    }
    return (
      <VStack spacing={5}>
        <Heading size={3}>{placeholderFallback(category.label?.label)}</Heading>
        <VStack spacing={1}>
          {category.features.map((feature) => (
            <FeatureItem
              key={feature.const_key}
              title={feature.labels[0].label}
              data-testid={`categoryItem-[${category.categoryKey}]`}
              isHighlighted={feature.isPremium || feature.isHighlighted}
            />
          ))}
        </VStack>
      </VStack>
    );
  };

  return (
    <Show when={shouldShowFeatures || shouldShowAdditionalInformation}>
      <SectionNew id="FEATURES" heading={i18n.t('featuresHeader')} isWithBreakLine>
        <Show when={shouldShowFeatures}>
          <Grid columns={[1, 2]} horizontalSpacing={8}>
            <GridItem span={1}>{renderCategory(features[0])}</GridItem>
            <GridItem span={1}>
              <VStack spacing={9}>{features.slice(1).map(renderCategory)}</VStack>
            </GridItem>
          </Grid>
        </Show>
        <Show when={shouldShowFeatures && shouldShowAdditionalInformation}>
          <Space vertical={7} />
        </Show>
        <Show when={shouldShowAdditionalInformation}>
          <Text size="large" alternative>
            Doplňující informace
          </Text>
          <Space vertical={2} />
          <CommentExpandable hideCommentFlag comment={vehicle?.featuresByManufacturer} />
        </Show>
      </SectionNew>
    </Show>
  );
}
