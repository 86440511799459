import {BaseFlagProps, FlagLabel} from 'platform/components';
import {Box, Center, HStack} from 'platform/foundation';

import {suffixTestId} from 'shared';

import {getColorsFromScheme, getFlagElementSizes} from './utils';

export type FlagProps = BaseFlagProps & {
  isFullWidth?: boolean;
  isBoldText?: boolean;
  isTransparent?: boolean;
};

export function FeatureItemFlag(props: FlagProps) {
  const {height, labelHorizontalPadding, textSize, borderRadius} = getFlagElementSizes(props.size);
  const {backgroundColor, color} = getColorsFromScheme(props.colorScheme, props.isSubtle);

  const isFullWidth = props.isFullWidth ?? false;

  return (
    <HStack justify="flex-start" maxWidth="100%" data-testid={suffixTestId('flagWrapper', props)}>
      <Box
        height={height}
        width={isFullWidth ? '100%' : undefined}
        paddingHorizontal={labelHorizontalPadding}
        backgroundColor={props.isTransparent ? undefined : backgroundColor}
        borderRadius={borderRadius}
        maxWidth="100%"
      >
        <Center height="100%">
          <FlagLabel
            color={props.isTransparent ? 'text.secondary' : color}
            size={textSize}
            isBold={props.isBoldText}
            label={props.label}
            data-testid={props['data-testid']}
          />
        </Center>
      </Box>
    </HStack>
  );
}
