import {useLayoutEffect} from 'react';
import {Outlet, useParams} from 'react-router-dom';

import {any, isNotNil, keys} from 'ramda';
import {isFalse, isNotNilOrEmpty, isTrue} from 'ramda-adjunct';

import {Error} from '../../(sections)/Error/Error';
import {Loading} from '../../(sections)/Loading/Loading';
import {useGetAllApiEnumsQuery} from '../../api/digitalCertificateApi';
import {useGetBusinessCaseOnlineOfferQuery} from '../../api/onlineOfferApi';
import {languageDialogItems} from '../../consts/languageDialogItems';
import {queryParams} from '../../consts/queryParams';
import {i18n} from '../../i18n/i18n';
import {getWorkspaceFromUri} from '../../utils/getWorkspaceFromUri';
import {useQueryState} from '../../utils/useQueryState';

const {workspace} = getWorkspaceFromUri();

export function ApplicationInitializeBoundary() {
  const params = useParams<{offerId: string}>();
  const [language] = useQueryState(queryParams.LNG);

  const {
    data: onlineOfferData,
    isError: isOnlineOfferQueryErrored,
    isFetching: isOnlineOfferQueryFetching,
  } = useGetBusinessCaseOnlineOfferQuery({
    offerId: params.offerId ?? '',
    workspace: workspace ?? '',
  });

  const {
    data: alphaCatalogue,
    isError: isAlphaCatalogueErrored,
    isFetching: isAlphaCatalogueFetching,
  } = useGetAllApiEnumsQuery({
    lang: language ?? i18n.resolvedLanguage,
  });

  const isFetching = any(isTrue, [isOnlineOfferQueryFetching, isAlphaCatalogueFetching]);

  const isErrored = any(isTrue, [isOnlineOfferQueryErrored, isAlphaCatalogueErrored]);

  const isAppReady = isNotNil(onlineOfferData) && isNotNil(alphaCatalogue);

  useLayoutEffect(() => {
    if (isSupportedLanguage(language) && language !== i18n.resolvedLanguage) {
      i18n.changeLanguage(language!);
    }

    if (isErrored) {
      return;
    }

    // TODO: add sentry capture https://carvago.atlassian.net/browse/T20-71741
    // if (!isAppReady) {
    //   // handleInitFlagsmith(`${workspace}__${params.auditId}`);
    //   getVehicleAudit({workspace, auditId: params.auditId})
    //     .unwrap()
    //     .then((data) => {
    //       if (!data) {
    //         return;
    //       }
    //       getVehicleCatalogue({
    //         vehicleType: data.vehicle.type,
    //         make: data.vehicle.make,
    //         lang: language ?? i18n.resolvedLanguage ?? FALLBACK_LANGUAGE,
    //       })
    //         .unwrap()
    //         .catch(noop);
    //       getAllApiEnums({lang: language ?? i18n.resolvedLanguage})
    //         .unwrap()
    //         .catch(noop);
    //     })
    //     .catch(noop);
    // }
  }, [isAppReady, isErrored, language]);

  // TODO: implement when we'll have GA
  // useEffect(() => {
  //   if (!isAppReady || environment.ENV_TYPE !== 'prod') {
  //     return;
  //   }
  //
  //   const dataLayer = {
  //     event: 'applicationInitializeBoundary',
  //     workspace,
  //     environment: environment.ENV_TYPE,
  //     auditId: vehicleAudit.audit.id,
  //     vehicleType: vehicleAudit.vehicle.type,
  //     make: vehicleAudit.vehicle.make,
  //     language: language ?? i18n.language,
  //   };
  //
  //   TagManager.dataLayer({dataLayer});
  // }, [isAppReady, language, vehicleAudit]);

  if (isErrored) {
    return <Error />;
  }

  if (isFetching || isFalse(isAppReady)) {
    return <Loading />;
  }

  return <Outlet />;
}

const isSupportedLanguage = (lng: any) =>
  isNotNilOrEmpty(lng) && keys(languageDialogItems).includes(lng);
