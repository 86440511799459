import {Nullish, sortByAsciiNumberLast} from 'shared';

import {Key, Label} from '../api/digitalCertificateApi';
import {DataUtilParams} from '../types/DataUtilParams';

type Feature = Key & {isHighlighted: boolean; isPremium: boolean};

export type CategorizedFeature = {
  categoryKey: string;
  label: Label | Nullish;
  features: Feature[];
};

export function getFeatures({alphaCatalogue, vehicle}: DataUtilParams) {
  if (!vehicle || !alphaCatalogue || !vehicle.features || !vehicle.features.length) {
    return [];
  }
  const featuresFromCatalogue = alphaCatalogue.find(
    (item) => item.property_name === 'feature' && item.vehicle_type === vehicle.type
  )?.keys;

  const categories = alphaCatalogue.find(
    (item) => item.property_name === 'feature_category' && item.vehicle_type === vehicle.type
  );

  if (!featuresFromCatalogue) {
    return [];
  }

  const intersectedFeaturesFromCatalogue = featuresFromCatalogue
    .map((feature) => {
      const matchingVehicleFeature = vehicle.features!.find(
        (vehicleFeature) => vehicleFeature.key === feature.const_key
      );
      if (!matchingVehicleFeature) {
        return null;
      }
      return {
        ...feature,
        isHighlighted: matchingVehicleFeature.isHighlighted,
        isPremium: matchingVehicleFeature.isPremium,
      };
    })
    .filter(Boolean) as Feature[];

  const featuresWithCategories = Object.groupBy(
    intersectedFeaturesFromCatalogue,
    (item) => item.categories[0]
  );

  const categoriesWithFeatures = Object.keys(featuresWithCategories).reduce(
    (acc, currentCategoryKey) => {
      const labelForCategory = categories?.keys.find(
        (category) => category.const_key === currentCategoryKey
      )?.labels[0];
      if (!labelForCategory) {
        return acc;
      }
      const features = featuresWithCategories[currentCategoryKey];
      if (!features) {
        return acc;
      }
      const sortedFeatures = features.sort((a, b) => {
        // sort alphabetically by default
        if (a.isHighlighted === b.isHighlighted && a.isPremium === b.isPremium) {
          return sortByAsciiNumberLast(a.labels[0].label, b.labels[0].label);
        }
        // sort highlighted and premium features first
        if (a.isHighlighted || a.isPremium) {
          return -1;
        }
        if (b.isHighlighted || b.isPremium) {
          return 1;
        }
        return 0;
      });
      return [
        ...acc,
        {
          categoryKey: currentCategoryKey,
          label: labelForCategory,
          features: sortedFeatures,
        },
      ];
    },
    [] as CategorizedFeature[]
  );
  return categoriesWithFeatures;
}
